import betweenDate from '../../../../mixins/betweenDate';
import {mapGetters} from 'vuex';
import {get} from "../../../../helpers/cookie";

const PERIOD = 60 * 60 * 24 * 60;

export default {
	data() {
		return {
			expireDate: PERIOD,
			isShow: false,
			endDate: '2022-05-31 23:59:59',
		};
	},
  name: 'AdditionalBenefit',
  mixins: [betweenDate],
  mounted() {
	this.setTime('2022-05-06 00:00:01', this.endDate);
	if(this.showBetweenDateTime || Number(get('increased_refill_bonus'))){
		this.isShow = true
	}
  },
  components: {
	Timer: require('../../../FinalPage/common/Timer/index.vue').default,
  },
  i18n: {
	messages: {
	  uk: {
		'Збільшена вигода': 'Збільшена вигода',
		'вигоди': 'вигоди',
		'днів': 'днів',
		'стандартна до кінця місяця': 'стандартна до кінця місяця',
		'Встигніть поповнити рахунок': 'Встигніть поповнити рахунок',
		'квітня': 'квітня',
		'Поповнити': 'Поповнити',
	  },
	  ru: {
		'Збільшена вигода': 'Увеличенная выгода',
		'вигоди': 'выгоды',
		'днів': 'дней',
		'стандартна до кінця місяця': 'стандартная до конца месяца',
		'Встигніть поповнити рахунок': 'Успейте пополнить счет',
		'квітня': 'апреля',
		'Поповнити': 'Пополнить',
	  }
	}
  }
};
