export default {
	messages: {
		ru: {
			'Публікуйте авто вигідно': 'Публикуйте авто выгодно',
			'та оплачуйте': 'и оплачивайте',
			'миттєво': 'мгновенно',
			'з Особистого': 'с Личного',
			'рахунку': 'счета',
			'Оплачуйте послуги': 'Оплачивайте услуги',
			'з вашого': 'с вашего',
			'Особистого рахунку та заощаджуйте': 'Личного счета и экономьте',
			'публікаціях': 'публикациях',
			'з': 'с',
			'рівнями': 'уровнями',
			'А з пакетом компаній «Автодилер» заощаджуйте': 'А с пакетом компаний «Автодилер»',
			'ще більше': 'экономьте',
			'Публікації та реклама ваших авто за вигідними цінами': 'Публикации и реклама ваших авто по выгодным ценам',
			'Виберіть суму поповнення Особистого рахунку та заощаджуйте на розміщенні та рівнях': 'Выберите сумму пополнения Личного счета и экономьте на размещении и уровнях',
			'вигідних цін на публікації та рівні': 'выгодных цен на публикации и уровни',
			'днів': 'дней',
			'Публікація': 'Публикация',
			'Поповнити': 'Пополнить',
			'Залишились питання': 'Остались вопросы',
			'Готова проконсультувати': 'Готова проконсультировать',
			'Ваш персональний менеджер': 'Ваш персональный менеджер',
			'Наталія': 'Наталья',
			'Дякуємо': 'Спасибо',
			'Кошти будуть зараховані на Особистий рахунок протягом 10 хвилин після оплати': 'Средства  будут начислены на Личный счет на протяжении 10 минут после оплаты',
			'Продаєте багато авто': 'Продаете много авто',
			'Заощаджуйте ще більше': 'Экономьте еще больше',
			'Замовляйте пакет компанії': 'Заказывайте пакет компании',
			'заощаджуйте на кожній публікації': 'экономьте на каждой публикации',
			'Дізнатись більше': 'Узнать больше',
			'Користуйтесь Особистим рахунком': 'Пользуйтесь Личным счетом',
			'для оплати послуг': 'для оплаты услуг',
			'Заощаджуйте на публікації авто': 'Экономьте на публикации авто',
			'Рекламуйте в ТОП за вигідною ціною': 'Рекламируйте в ТОП по выгодной цене',
			'Замовляйте розширену перевірку': 'Заказывайте расширенную проверку',
			'Замовляйте технічну перевірку на СТО': 'Заказывайте техническую проверку на СТО',
			'Часті запитання': 'Часто задаваемые вопросы',
			'Як довго я можу користуватись знижками': 'Как долго я могу пользоваться скидками',
			'Всі вигоди для бізнесу, які ми пропонуємо, діють 30 днів. Відповідно, знижки на публікації будуть доступні вам протягом 30 днів з моменту поповнення. А невикористані кошти залишаться на вашому рахунку й після цього терміну': 'Все выгоды для бизнеса, которые мы предлагаем, действуют 30 дней. Соответственно, скидки на публикации будут доступны вам в течение 30 дней с момента пополнения. А неиспользованные средства останутся на вашем счету и после этого срока.',
			'А можна поповнити рахунок двічі й отримати подвійну знижку': 'А можно пополнить счет дважды и получить двойную скидку',
			'При поповненні на дві суми знижки не сумуються. У разі поповнення на меншу суму при вже діючих вигодах — ви продовжите користуватись наявними вигодами. А при поповненні на суму, яка передбачає більші вигоди, знижки на публікації збільшаться згідно суми поповнення, а їх дія продовжиться на 30 днів. Що стосується знижок, передбачених поповненням Особистого рахунку, та знижок, включених до пакету «Автодилер», то вони сумуються. Поєднавши їх, можна отримати максимально вигідну ціну публікацій': 'При пополнении на две суммы скидки не суммируются. В случае пополнения на меньшую сумму при уже действующих выгодах — вы продолжите пользоваться имеющимися выгодами. А при пополнении на сумму, которая предусматривает большие выгоды, скидки на публикации увеличатся согласно суммы пополнения, а их действие продолжится на 30 дней. Что касается скидок, предусмотренных пополнением Личного счета, и скидок, включенных в пакет «Автодилер», то они суммируются. Соединив их, можно получить максимально выгодную цену публикаций',
			'На що можна витратити гроші з Особистого Рахунку після поповнення': 'На что можно потратить деньги с Личного счета после пополнения',
			'В першу чергу, для отримання максимальної вигоди, ми рекомендуємо придбати пакет «Автодилер». Після цього ви зможете публікувати авто з максимально можливими знижками. Крім публікацій, за кошти з Особистого рахунку ви можете піднімати авто в ТОП, замовляти розширені перевірки VIN-кодів або перевірки на СТО. Їх результати допоможуть привернути увагу покупців та швидше продати': 'В первую очередь, для получения максимальной выгоды, мы рекомендуем купить пакет "Автодилер". После этого вы сможете публиковать авто с максимально возможными скидками. Кроме публикаций, за деньги с Личного счета вы можете поднимать авто в ТОП, заказывать расширенные проверки VIN-кодов или проверки на СТО. Их результаты помогут привлечь внимание покупателей и быстрее продать',
			'Якщо я поповню рахунок на 5 000 на початку місяця, а через тиждень ще на 15 000 — які в мене будуть знижки і скільки вони будуть діяти': 'Если я пополню счет на 5000 в начале месяца, а через неделю еще на 15 000 — какие у меня будут скидки и сколько они будут действовать',
			'Після поповнення на 15 000, ви отримаєте 15% вигоди на публікації, які будуть діяти 30 днів': 'После пополнения на 15 000, вы получите 15% выгоды на публикации, которые будут действовать 30 дней',
			'Введіть промокод і отримайте додаткову вигоду': 'Введите промокод и получите дополнительную выгоду',
			'на публікації': 'на публикации',
			'Введіть промо-код': 'Введите промо-код',
			'Активувати': 'Активировать',
			'додаткової': 'дополнительной',
			'вигоди': 'выгоды',
			'будуть доступні після поповнення рахунку': 'будет доступно после пополнения счета',
			'Перевірте, чи правильно ви ввели промокод': 'Проверьте, правильно ли вы ввели промокод',
			'Авторизуйтеся': 'Авторизуйтесь',
			'щоб використати промокод на додаткову вигоду': 'чтобы использовать промокод на дополнительную выгоду',
			'від промокоду': 'от промокода',
			'уже активовано': 'уже активированы',
			'day': 'дней | день | дня | дней',
			'активовано': 'активирован',
		},
		uk: {
			'Публікуйте авто вигідно': 'Публікуйте авто вигідно',
			'та оплачуйте': 'та оплачуйте',
			'миттєво': 'миттєво',
			'з Особистого': 'з Особистого',
			'рахунку': 'рахунку',
			'Оплачуйте послуги': 'Оплачуйте послуги',
			'з вашого': 'з вашого',
			'Особистого рахунку та заощаджуйте': 'Особистого рахунку та заощаджуйте',
			'публікаціях': 'публікаціях',
			'з': 'з',
			'рівнями': 'рівнями',
			'А з пакетом компаній «Автодилер» заощаджуйте': 'А з пакетом компаній «Автодилер» заощаджуйте',
			'ще більше': 'ще більше',
			'Публікації та реклама ваших авто за вигідними цінами': 'Публікації та реклама ваших авто за вигідними цінами',
			'Виберіть суму поповнення Особистого рахунку та заощаджуйте на розміщенні та рівнях': 'Виберіть суму поповнення Особистого рахунку та заощаджуйте на розміщенні та рівнях',
			'вигідних цін на публікації та рівні': 'вигідних цін на публікації та рівні',
			'днів': 'днів',
			'Публікація': 'Публікація',
			'Поповнити': 'Поповнити',
			'Залишились питання': 'Залишились питання',
			'Готова проконсультувати': 'Готова проконсультувати',
			'Ваш персональний менеджер': 'Ваш персональний менеджер',
			'Наталія': 'Наталія',
			'Дякуємо': 'Дякуємо',
			'Кошти будуть зараховані на Особистий рахунок протягом 10 хвилин після оплати': 'Кошти будуть зараховані на Особистий рахунок протягом 10 хвилин після оплати',
			'Продаєте багато авто': 'Продаєте багато авто',
			'Заощаджуйте ще більше': 'Заощаджуйте ще більше',
			'Замовляйте пакет компанії': 'Замовляйте пакет компанії',
			'заощаджуйте на кожній публікації': 'заощаджуйте на кожній публікації',
			'Дізнатись більше': 'Дізнатись більше',
			'Користуйтесь Особистим рахунком': 'Користуйтесь Особистим рахунком',
			'для оплати послуг': 'для оплати послуг',
			'Заощаджуйте на публікації авто': 'Заощаджуйте на публікації авто',
			'Рекламуйте в ТОП за вигідною ціною': 'Рекламуйте в ТОП за вигідною ціною',
			'Замовляйте розширену перевірку': 'Замовляйте розширену перевірку',
			'Замовляйте технічну перевірку на СТО': 'Замовляйте технічну перевірку на СТО',
			'Часті запитання': 'Часті запитання',
			'Як довго я можу користуватись знижками': 'Як довго я можу користуватись знижками',
			'Всі вигоди для бізнесу, які ми пропонуємо, діють 30 днів. Відповідно, знижки на публікації будуть доступні вам протягом 30 днів з моменту поповнення. А невикористані кошти залишаться на вашому рахунку й після цього терміну': 'Всі вигоди для бізнесу, які ми пропонуємо, діють 30 днів. Відповідно, знижки на публікації будуть доступні вам протягом 30 днів з моменту поповнення. А невикористані кошти залишаться на вашому рахунку й після цього терміну',
			'А можна поповнити рахунок двічі й отримати подвійну знижку': 'А можна поповнити рахунок двічі й отримати подвійну знижку',
			'При поповненні на дві суми знижки не сумуються. У разі поповнення на меншу суму при вже діючих вигодах — ви продовжите користуватись наявними вигодами. А при поповненні на суму, яка передбачає більші вигоди, знижки на публікації збільшаться згідно суми поповнення, а їх дія продовжиться на 30 днів. Що стосується знижок, передбачених поповненням Особистого рахунку, та знижок, включених до пакету «Автодилер», то вони сумуються. Поєднавши їх, можна отримати максимально вигідну ціну публікацій': 'При поповненні на дві суми знижки не сумуються. У разі поповнення на меншу суму при вже діючих вигодах — ви продовжите користуватись наявними вигодами. А при поповненні на суму, яка передбачає більші вигоди, знижки на публікації збільшаться згідно суми поповнення, а їх дія продовжиться на 30 днів. Що стосується знижок, передбачених поповненням Особистого рахунку, та знижок, включених до пакету «Автодилер», то вони сумуються. Поєднавши їх, можна отримати максимально вигідну ціну публікацій',
			'На що можна витратити гроші з Особистого Рахунку після поповнення': 'На що можна витратити гроші з Особистого Рахунку після поповнення',
			'В першу чергу, для отримання максимальної вигоди, ми рекомендуємо придбати пакет «Автодилер». Після цього ви зможете публікувати авто з максимально можливими знижками. Крім публікацій, за кошти з Особистого рахунку ви можете піднімати авто в ТОП, замовляти розширені перевірки VIN-кодів або перевірки на СТО. Їх результати допоможуть привернути увагу покупців та швидше продати': 'В першу чергу, для отримання максимальної вигоди, ми рекомендуємо придбати пакет «Автодилер». Після цього ви зможете публікувати авто з максимально можливими знижками. Крім публікацій, за кошти з Особистого рахунку ви можете піднімати авто в ТОП, замовляти розширені перевірки VIN-кодів або перевірки на СТО. Їх результати допоможуть привернути увагу покупців та швидше продати',
			'Якщо я поповню рахунок на 5 000 на початку місяця, а через тиждень ще на 15 000 — які в мене будуть знижки і скільки вони будуть діяти': 'Якщо я поповню рахунок на 5 000 на початку місяця, а через тиждень ще на 15 000 — які в мене будуть знижки і скільки вони будуть діяти',
			'Після поповнення на 15 000, ви отримаєте 15% вигоди на публікації, які будуть діяти 30 днів': 'Після поповнення на 15 000, ви отримаєте 15% вигоди на публікації, які будуть діяти 30 днів',
			'Введіть промокод і отримайте додаткову вигоду': 'Введіть промокод і отримайте додаткову вигоду',
			'на публікації': 'на публікації',
			'Введіть промо-код': 'Введіть промо-код',
			'Активувати': 'Активувати',
			'додаткової': 'додаткової',
			'вигоди': 'вигоди',
			'будуть доступні після поповнення рахунку': 'будуть доступні після поповнення рахунку',
			'Перевірте, чи правильно ви ввели промокод': 'Перевірте, чи правильно ви ввели промокод',
			'Авторизуйтеся': 'Авторизуйтеся',
			'щоб використати промокод на додаткову вигоду': 'щоб використати промокод на додаткову вигоду',
			'від промокоду': 'від промокоду',
			'уже активовано': 'уже активовано',
			'day': 'днів | день | дні | днів',
			'активовано': 'активовано',
		}
	}
};
