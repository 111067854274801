/* eslint-disable global-require */
module.exports = {
  mixins: [require('./slonDataLogger')],
  methods: {
    clickLogger(event) {
      if (event.target?.attributes) {
        const action = event?.target?.attributes['analytic-action']?.value;
        if (action) {
          this.sendLogs(action);
        }
      }
    },
    sendLogs(action, additionalParams = {}) {
      try {
        const [clickAction, actionBlock] = typeof action === 'string' ? action?.split('-') : [undefined];
        this._slonik({
          event_id: action ? this.analyticData?.actionEventId : this.analyticData?.openPageEventId,
          screentype: this.isMobile ? 2 : 1,
          click_action: clickAction,
          action_block: actionBlock,
          current_url: encodeURI(this.clearAutoRiaUrl(window.location.href)),
          previous_url: encodeURI(this.clearAutoRiaUrl(document.referrer)),
          project_id: 1,
          ...this.formAdditionalLogData || {},
          ...additionalParams,
        });
      } catch (e) {
        console.error('=== ERROR in vueLogger[sendLogs] ===', e.toString());
      }
    },
  },
};
