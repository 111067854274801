import { mapActions, mapGetters } from 'vuex';
import AdditionalBenefit from './components/AdditionalBenefit/index.vue';
import betweenDate from '../../mixins/betweenDate';
import vueLogger from '../../mixins/vueLogger';

const storeModule = require('../../store/cabinet');

export default {
  mixins: [require('../../mixins/benefitsDataLogger'), betweenDate, vueLogger],
  name: 'LandingDiscounts',
  data() {
    return {
      analyticData: {
        openPageEventId: 757,
        actionEventId: 758,
      },
      items: [],
      orderCreated: false,
      checkedItem: false,
      balanceMap: {
        account: 0,
        promo: 0,
        publication: 0,
      },
      isBalanceExist: undefined,
      isUserDataExist: undefined,
      promoCode: '',
      isLoaded: false,
      wrongPromoCode: false,
      promoCodeDiscount: 0,
      showCongMessage: false,
      successfulPromoCode: false,
      userPromoCodes: [],
      activePromoCodeText: false,
      activePromoCode: {},
      isShowPromoBlock: false,
      expirePromoDays: 0,
    };
  },
  components: {
    AdditionalBenefit,
  },
  metaInfo() {
    return {
      title: `AUTO.RIA – ${this.$t('Публікуйте авто вигідно та оплачуйте миттєво з Особистого рахунку')}`,
      meta: [
        { name: 'description', content: this.$t('') },
        { property: 'og:site_name', content: 'AUTO.RIA' },
        { property: 'og:title', content: `AUTO.RIA – ${this.$t('Публікуйте авто вигідно та оплачуйте миттєво з Особистого рахунку')}` },
        { property: 'og:description', content: this.$t('') },
        { property: 'og:image', content: 'https://css.riastatic.com/images/og-placeholder.png' },
        { name: 'ROBOTS', content: 'NOINDEX, NOFOLLOW' },
      ],
      link: [
        { rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/help/position/' },
        { rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/help/position/' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/help/position/' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/help/position/' },
        { rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/help/position/' },
        { rel: 'canonical', href: 'https://auto.ria.com/help/position/' },
      ],
    };
  },
  i18n: require('./i18n').default,
  init({ store, context }) {
    store.registerModule('cabinet', storeModule);
    let cookies = {};
    if (context) {
      cookies = context.request.headers.cookie;
    }
    store.dispatch('cabinet/balance/balance', cookies);
  },
  mounted() {
    this.setTime('2021-09-23', '2021-10-21');
    this.showPromoBlock();
    this.discountsList({ recommendedDiscount: this.$route.query.recommendedDiscount }).then((items) => {
      if (Array.isArray(items)) {
        this.items = items;
        items.forEach((item) => {
          if (item.templates.isActive) {
            this.checkedItem = true;
          }
        });
      }
      this.isLoaded = true;
    });
    this.getUserPromoCodes();
  },
  computed: {
    ...mapGetters({
      userData: 'Common/userData',
      balance: 'cabinet/balance/balance',
    }),
    isDataExist() {
      return this.isUserDataExist && this.isBalanceExist;
    },
    formAdditionalLogData() {
      return {
        balance_personal: this.balanceMap.account,
        balance_promotional: this.balanceMap.promo,
        balance_publications: this.balanceMap.publication,
      };
    },
  },
  watch: {
    isDataExist() {
      this.sendLogs();
    },
    balance() {
      Object.keys(this.balanceMap).forEach((key) => {
        this.balance.forEach((element) => {
          if (key === element.name) {
            // this.balanceMap[key] = element.value?.data;
            this.balanceMap[key] = this._result(element, 'value.data');
          }
        });
      });
      this.isBalanceExist = true;
    },
    userData(userData, oldData) {
      if (userData.webClientId) {
        this.isUserDataExist = true;
        if (!userData.userId && !oldData.webClientId) {
          this.sendLogs();
        }
      }
    },
    promoCode() {
      this.wrongPromoCode = false;
      this.showCongMessage = false;
      this.activePromoCodeText = false;
    },
  },
  methods: {
    ...mapActions({
      discountsList: 'others/publicationDiscountsList',
      createRefillOrder: 'others/createRefillOrder',
      checkPromoCode_: 'others/checkPromoCode',
      fetchUserPromoCodes: 'others/getUserPromoCodes',
    }),
    createOrder(event) {
      event.preventDefault();
      const { userId } = this.userData || {};
      if (userId) {
        const { target: { elements: { amount: { value } = {} } = {} } = {} } = event;
        this.orderCreated = true;
        const options = { amount: value };
        if (this.successfulPromoCode) {
          Object.assign(options, { promoCode: this.promoCode });
        }
        if (this.isDesktop) {
          const billingWindow = window.open('https://auto.ria.com/demo/partials/loader/wait.html', '_blank');
          this.createRefillOrder(options)
            .then((response) => {
              billingWindow.location.href = `https://oplaty.ria.com/auto/order/${this._result(response, 'orderId')}`;
              this.sendLogs(2, {
                billing_order_id: this._result(response, 'orderId', 0),
                search_text: this.activePromoCode.value,
                is_discount: this.activePromoCode.discount,
              });
            }).catch((error) => {
              console.error(`Refill order error -->${JSON.stringify(error)}`);
              billingWindow.close();
            });
        } else {
          this.createRefillOrder(options)
            .then((response) => {
              window.location = `https://oplaty.ria.com/auto/order/${this._result(response, 'orderId')}`;
              this.sendLogs(2, {
                billing_order_id: this._result(response, 'orderId', 0),
                search_text: this.activePromoCode.value,
                is_discount: this.activePromoCode.discount,
              });
            }).catch((error) => {
              console.error(`Refill order error -->${JSON.stringify(error)}`);
            });
        }
      } else {
        document.location = `${this.langPrefix}/login.html?from_url=${this.$route.fullPath}`;
      }
    },
    checkPromoCode() {
      if (this.promoCode) {
        this.checkPromoCode_({ promoCode: this.promoCode }).then(({
          id = 0, discount = 0, value = '', endDate = '',
        }) => {
          if (id) {
            this.sendLogs(7, {
              search_text: this.promoCode,
              is_discount: discount,
              error_add_advert_form_application: 0,
            });
            this.getExpirePromoDays(endDate);
            // eslint-disable-next-line max-len
            const isActivePromoCode = this.userPromoCodes.find((el) => el.value === value && el.status === 2);
            if (isActivePromoCode) {
              this.promoCodeDiscount = this._result(isActivePromoCode, 'discount');
              this.activePromoCode = isActivePromoCode;
              this.activePromoCodeText = true;
            } else {
              Object.assign(this.activePromoCode, { value });
              this.promoCodeDiscount = discount;
              this.showCongMessage = true;
              this.successfulPromoCode = true;
            }
          } else {
            this.sendLogs(7, {
              search_text: this.promoCode,
              is_discount: discount,
              error_add_advert_form_application: 1,
            });
            this.wrongPromoCode = true;
          }
        });
      }
    },
    showPromoBlock() {
      const show = this.$route.query.promo;
      if (show || this.showBetweenDate) {
        this.isShowPromoBlock = true;
      }
    },
    getUserPromoCodes() {
      if (this.isShowPromoBlock) {
        this.fetchUserPromoCodes().then((list) => {
          const lastItem = list[list.length - 1];
          const status = this._result(lastItem, 'status') || 0;
          const discount = this._result(lastItem, 'discount') || 0;
          const endDate = this._result(lastItem, 'endDate') || 0;
          this.getExpirePromoDays(endDate);
          this.userPromoCodes = list;
          if (status === 1) {
            this.showCongMessage = true;
            this.promoCodeDiscount = discount;
            this.activePromoCode = lastItem;
          } else if (status === 2) {
            this.activePromoCodeText = true;
            this.promoCodeDiscount = discount;
            this.activePromoCode = lastItem;
          }
        });
      }
    },
    getExpirePromoDays(endDate) {
      this.expirePromoDays = this.m(endDate).diff(this.m(), 'days');
    },
  },
};
